import { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import './Header.css';
import ShredderzBrand from '../images/shredderz-brand.png'

function Header() {

	const [ activeLink, setActiveLink ] = useState('onyx-nft');

	useEffect(() => {
		// vidRef.current.play();
	}, [])

	function goToSection(id) {
		setActiveLink(id)
		document.getElementById(id).scrollIntoView({ behavior: "smooth" })
	}

	return (
		<>
			<Navbar id="site-nav" sticky="top" bg="#000000" justify="true" className="p-0 mb-4" expand="xl" style={{ backgroundColor: '#000' }}>
				<Navbar.Brand href="/backstage">
					<img
						src={ShredderzBrand}
						height="80px"
						className="d-inline-block align-top ms-3"
						alt="The Shredderz"
					/>
				</Navbar.Brand>
				
				<Navbar.Toggle aria-controls="basic-navbar-nav" className="me-3" />
				<Navbar.Collapse id="basic-navbar-nav">
					
					<Nav className="justify-content-center" activeKey="#home" variant="pills" sticky="top" style={{ width: '100%' }}>
						<Nav.Item>
							<Nav.Link href="/backstage#about" onClick={() => goToSection('about')} className={activeLink === 'about' ? 'active' : ''}>
								About
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/backstage#access-pass" onClick={() => setActiveLink('access-pass')} className={activeLink === 'access-pass' ? 'active' : ''}>
								Access Pass
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/backstage#shredifesto" onClick={() => setActiveLink('shredifesto')} className={activeLink === 'shredifesto' ? 'active' : ''}>
								Shredifesto
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/backstage#the-band" onClick={() => setActiveLink('the-band')} className={activeLink === 'the-band' ? 'active' : ''}>
								The Band
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/backstage#faqs" onClick={() => setActiveLink('faqs')} className={activeLink === 'faqs' ? 'active' : ''}>
								FAQs
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/backstage#links" onClick={() => setActiveLink('links')} className={activeLink === 'links' ? 'active' : ''}>
								Links
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="//shop.theshredderz.com">
								Shop
							</Nav.Link>
						</Nav.Item>
						{/*
						<Nav.Item>
							<Nav.Link eventKey="link-2">
							<Image src="images/tillavision.png" alt="onyx nft" />
							</Nav.Link>
						</Nav.Item>
						*/}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
}

export default Header;
