import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Card, Accordion } from 'react-bootstrap';

import './Backstage.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

// import ShredderBumper from '../assets/shredderzbumper.mp4'

import BehindTheMusic from '../images/behind-the-music.png'
import TVPreview from '../images/tv-preview.jpg'

import PFPTitle from '../images/pfp-title.png'
import AccessPassTitle from '../images/access-pass-title.png'
import AccessPass from '../images/access-pass.png'

import Shredifesto from '../images/shredifesto.png'

import TheBandTitle from '../images/meet-the-band.png'
import vincent from '../images/band/vincent.png'
import raz from '../images/band/raz.jpg'
import weezel from '../images/band/weezel.jpg'
import nigel from '../images/band/nigel.jpg'
import donny from '../images/band/donny.jpg'
import lydia from '../images/band/lydia.jpg'

import FAQTitle from '../images/faq-title.png'

import LinkList from '../components/LinkList'

const band = [
	{
		"img": raz,
		"name": "Erasmus “RAZ” Von Ripper",
		"role": "Vocalist for The Shredderz",
		"hometown": "Cleveland, OH",
		"desc": "Raz's on-stage persona, sex appeal, and incredible vocal chords made him a legend by the time we was 14 yrs old. But now, as the lead singer of the mega-metal band, The Shredderz, there's nothing stopping them from conquering the Metaverse."
	}, {
		"img": weezel,
		"name": "Weezel",
		"role": "Lead Guitarist for The Shredderz",
		"hometown": "San Francisco, CA",
		"desc": "Weezel grew up “couch surfing” most of his life. Raised on a strict diet of Bay Area thrash metal, it's no wonder he has a NEED for SPEED. Although, there is no proof, Weezel claims to be the love child of James Hetfield."
	}, {
		"img": vincent,
		"name": "Vincent Barlow",
		"role": "Lead Guitarist for The Shredderz",
		"hometown": "Unknown",
		"desc": "Vincent is a true student of the art of guitar. With The Shredderz, Vincent looks to take his place alongside with guitar heroes, such as, Richie Blackmore, Randy Rhodes, and Malmsteen, to name just a few. And the way The Shredderz are blowing up, he looks to do just that."
	}, {
		"img": nigel,
		"name": "Nigel Barrymore",
		"role": "Bass player for The Shredderz",
		"hometown": "Birmingham, England",
		"desc": "Nigel grew up in the British Punk scene of the late 70's. Nigel had moderate success with his first band, Mickey Sweet and the Loadz, but after their break-up, joined the British Navy. After seeing The Shredderz play at CBGB's while on shore leave in NY, he promtly went AWOL and joined the band."
	}, {
		"img": donny,
		"name": "Donny Deckmen",
		"role": "Drummer for The Shredderz",
		"hometown": "Detroit, MI",
		"desc": "Donny likes to smash things. His cave-man style aggression on the drums is unrivaled. Funny thing is, no one in the rest of the band remembers him auditioning,…he was just THERE."
	}, {
		"img": lydia,
		"name": "Lydia Moongrave",
		"role": "Occultist & Band Manager of The Shredderz",
		"hometown": "Salem, Massachusetts",
		"desc": "This manager's STRICTLY BUSINESS. She's snotty, sarcastic and you don't want to be on the receiving end of her wrath. On rare occasions, whenever there are kittens around, you might see her smile."
	}
]

function Backstage() {

	useEffect(() => {
		// vidRef.current.play();
	}, [])

	return (
		<>
			<Header />
			<Container>

				{/* Page 1 - Intro */}
				{/*
				<div id="home-intro" className="row mb-3">
					<Col>
						<div id="home-container">
							<video ref={vidRef} loop playsinline autoPlay muted style={{ width: '100%', height: 'auto', opacity: 1, marginTop: '-50px' }}>
								<source src={ShredderBumper} type="video/mp4" />
							</video>
						</div>
					</Col>
				</div>
				*/}
		
				{/* Page 2 - Behind the Music */}
				<Row id="about">
					<Col>
						<Image src={BehindTheMusic} fluid />
					</Col>
				</Row>
				<Row className="mb-5 p-3" style={{ backgroundColor: '#000000' }}>
					<Col xs={12} lg={6}>
						<p>
							Forged deep in the fiery underbelly of the Metaverse, the biggest, baddest shred crew 
							ever assembled has emerged. The Shredderz are an all-star collective of metal luminaries 
							uniting under a common banner to melt your f☠️cking face off. 
						</p>
						<p>
							The Shredderz are a web-3 native, virtual band poised to spread the shred across this 
							realm and beyond. Thanks to a dealing with the Dark Lord, they are now ready to fundamentally 
							change the face of metal and they're bringing their loyal following along with them.  
						</p>
						<p>
							The band will be tapping their community as a crowd-sourced manager. This means you can 
							help vote on band matters like song choice, collaborations, merch designs, and more! 
							Because The Shredderz take care of their own, members will also receive exclusive 
							content and priority access to future drops from The Shredderz universe. 
						</p>
						<p>
							🎧Turn it up & sell your soul to The Shredderz ☠️
						</p>
					</Col>
					<Col xs={12} lg={6} style={{ textAlign: 'center' }}>
						<Image src={TVPreview} fluid />
						<Link to="/" className="link-button" style={{ fontSize: '1.5rem' }}>TOUR THE STUDIO</Link>
					</Col>
				</Row>

				<Row id="pfp" className="mt-5" style={{ paddingTop: '100px' }}>
					<Col>
						<Image src={PFPTitle} fluid />
					</Col>
				</Row>
				<div id="pfp-content">
					<h2>Shredz PFP</h2>
					<p>
						"Shredz" is a collection of 6,666 Shredderz, a groundbreaking NFT collection that merges music, 
						art, and community like never before. The Shredderz are paving the way for a new era of fan 
						engagement and empowerment in the metal space. Each of the 6,666 hand-drawn characters in the 
						collection features a unique Shredderz song with the help of Lifescore, making every piece a 
						musical journey. Holders of a Shred NFT unlocks access to a gamified listening experience on 
						Audius. By listening, they can earn rewards such as additional music, festival tickets, 
						exclusive merchandise, and more. Shredz collection holders enjoy exclusive perks, including 
						priority access to drops, voting on merchandise designs, and influencing band decisions like 
						single choices and cover songs.
					</p>
					<a href="https://opensea.io/collection/shredderz-pfp/overview" 
						className="link-button" style={{ fontSize: '2rem', textAlign: 'center' }}
						target="_blank">BUY NOW!</a>

					<a href="https://drive.google.com/file/d/1Jl4Ai8iWt1FdHic1to2gU-mgDpCFdzuM/view?usp=drive_link"
						style={{ fontSize: '2rem', textAlign: 'center', marginTop: '2rem', display: 'block' }}
						target="_blank">
						Also check out the "Behind the Shred" Companion video here
					</a>
				</div>

				<Row id="access-pass" className="mt-5" style={{ paddingTop: '100px' }}>
					<Col>
						<Image src={AccessPassTitle} fluid />
					</Col>
				</Row>
				<div>
					<h2>Join The Shredvolution and Become Part of the Band!</h2>
					<Row>
						<Col xs={12} lg={6}>
							<Image src={AccessPass} fluid />
						</Col>
						<Col xs={12} lg={6}>
							<div id="access-pass-content">
								<p>
									Guest list is kinda tight, but we still got a spot for you if you want to be one 
									of the first to join the Shredderz community! On May 16, we're releasing a FREE 
									mint (plus, gas!) of 1,000 Access Passes. Get on the bus now before we leave for tour!
								</p>
								<ul id="access-pass-points">
									<li>Priority access to future drops</li>
									<li>Vote and say in some band decisions</li>
									<li>Exclusive access to promos and merch discounts</li>
									<li>Dynamic art that will evolve as band hits streaming numbers</li>
								</ul>
								<a href="https://opensea.io/collection/shredderz/drop" className="link-button" style={{ fontSize: '2rem', textAlign: 'center' }}>SOLD OUT!</a>
							</div>
							
						</Col>
					</Row>
				</div>

				<Row id="shredifesto" className="mt-5" style={{ paddingTop: '100px' }}>
					<Col>
						<Image src={Shredifesto} fluid />
					</Col>
				</Row>
				<div id="shredifesto_wrapper">
					<div>
						<p>
							The goal of the Shredderz is to fill a HUGE f☠️king hole for metalheads in the metaverse 
							and beyond. We're not you typical band. We're a community driven, virtual supergroup 
							fueled by the belief that a music scene is one of the most positive (and powerful) 
							communities that someone can be a part of. That's why we've created a community where 
							we can turn that sh*t up to eleven and allow fans to be a part of the creative process 
							and co-write the narrative of The Shredderz universe alongside us.  
						</p>
						<p>
							As a Shredderz holder, you get a say in critical band decisions like single choice, 
							collaborations, artists, merch design, future bands we work with and even touring plans. 
							Plus we take care of our own, so you'll get priority access to all future Shredderz 
							drops and exclusive content. 
						</p>
						<p>
							But shredding hard isn't all we're going to do with the Shredderz. We're aiming to be the 
							TOOL of Web3, pushing the boundaries of how it's use to make a killer experience for all 
							our holders. We're the first virtual band to link streaming data with NFTs. What does 
							that mean? As an access pass holder, you'll be helping to shape the future of the project 
							and you'll get rewarded as the band does better. It's like our community is the band 
							manager, and we're going to make damn sure they get their cut of the success through artist access and utility. 
						</p>
						<p>
							The mission is simple…revolutionize what a music community can be. We want to inspire 
							and empower a new generation of shredders and metal fans to create, share, and connect 
							in ways never thought possible - not just with The Shredderz but with their own projects 
							as well. Our vision is a world where music isn't JUST about entertainment. It's a 
							platform for collaboration, community, and innovation. 
						</p>
						<p>
							Jump on the tour bus and join us in this journey to the future of music. Together, we're 
							going to form the biggest, baddest, loudest crew ever to melt everyone's face off. So let 
							us know… are you ready to join the band.
						</p>
					</div>
				</div>

				<div id="the-band" className="mt-5" style={{ paddingTop: '100px' }}>
					<Image src={TheBandTitle} fluid />
					<Row>
						{band.map((member, idx) => {
							return (
								<Col key={idx} sm={6} lg={4} className="pb-5">
									<Card>
										<Image src={member.img} />
										<div className="p-3 mt-3">
											{member.name}<br />
											{member.role}<br />
											Hometown: {member.hometown}<br />
											<p>
												{member.desc}
											</p>
										</div>
									</Card>
								</Col>
							)
						})}
					</Row>
				</div>

				<div id="faqs" className="page-section mt-5" style={{ paddingTop: '100px' }}>
					<Image src={FAQTitle} fluid />
					<Row>
						<Col>
							<Accordion className="mt-4">
								<Accordion.Item eventKey="0">
									<Accordion.Header>Who are the Shredderz?</Accordion.Header>
									<Accordion.Body>
										The Shredderz is a virtual metal band backed by many of today's top Rock and Roll and Heavy 
										Metal musicians but you will only know them at Raz, Vincent, Weasel, Donny, Nigel along 
										with their manager Lydia.
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>How do I buy a Shredderz Access Pass?</Accordion.Header>
									<Accordion.Body>
										Access Passes are dropping May 16 on Opensea with a supply of 1,000 available to be claimed 
										for FREE, all you will have to pay is a gas. Once all Access Passes have been claimed, you 
										may have the opportunity to buy a pass from a current holder on Opensea.
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="2">
									<Accordion.Header>What is a gas fee?</Accordion.Header>
									<Accordion.Body>
										<p>
											A gas fee is the transaction fee required to process and record transactions on 
											the blockchain. Gas fee costs vary based on the size and complexity of the 
											transaction and the network congestion at the time.
										</p>
										<p>
											In order to complete your free Access Pass transaction you will need enough ETH (ethereum) 
											in your crypto wallet to cover the gas fees at the time of the transaction.
										</p>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="3">
									<Accordion.Header>When can I claim an Access Pass?</Accordion.Header>
									<Accordion.Body>
										ACCESS PASSES WILL BE RELEASED ON OPENSEA ON MAY 16TH AT 1PM EST. YOU CAN FIND THE DETAILS HERE! ( <a href="https://opensea.io/collection/shredderz/drop">LINK</a> )
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="4">
									<Accordion.Header>What is an NFT?</Accordion.Header>
									<Accordion.Body>
										NFTs are the next generation of collectibles that can be traded and sold.  Look at it like it's your membership into the fan club.
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="5">
									<Accordion.Header>What do I get when with my Access Pass?</Accordion.Header>
									<Accordion.Body>
										Access passes will provide priority access to future drops and will allow holders 
										to vote on many band decisions. You can think of Access Pass holders as extended 
										members of The Shredderz crew.  On top of that,  as The Shredderz hit new streaming 
										milestones, the Access Pass art will evolve and unlock amp'd up rewards.
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="6">
									<Accordion.Header>What is a dynamic NFT?</Accordion.Header>
									<Accordion.Body>
										<p>
											A dynamic NFT is a type of NFT that possesses the ability to change or evolve 
											over time. Unlike traditional static NFTs, which represent a fixed digital asset, 
											dynamic NFTs incorporate programmable elements or interactive features that can 
											modify their appearance, characteristics, or behavior.
										</p>
										<p>
											Our dynamic Access Passes are "powered by Chainlink"
										</p>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				</div>
		
				<LinkList />
			</Container>
			<Footer />
		</>
	);
}

export default Backstage;
