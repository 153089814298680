const links = [
	{
		"title": "STREAM THE SHREDDERS DEBUT SINGLE",
		"url": "https://radi.al/shredderz"
	},
	{
		"title": "OPENSEA COLLECTION",
		"url": "https://opensea.io/collection/shredderz/drop"
	},
	{
		"title": "MERCH STORE",
		"url": "https://shop.theshredderz.com/"
	},
	{
		"title": "CONTEST",
		"url": "https://radi.al/shredderztunespeak"
	},
	{
		"title": "TWITTER",
		"url": "https://twitter.com/TheShredderzNFT"
	},
	{
		"title": "INSTAGRAM",
		"url": "https://www.instagram.com/theshredderz/"
	},
	{
		"title": "TIKTOK",
		"url": "https://www.tiktok.com/@theshredderz"
	},
	{
		"title": "YOUTUBE",
		"url": "https://www.youtube.com/@theshredderz"
	},
	{
		"title": "Facebook",
		"url": "https://www.facebook.com/TheShredderzNFT"
	},
	{
		"title": "Discord",
		"url": "https://discord.com/invite/jHW479uGPa"
	}
]

function LinkList() {

	return (
		<div id="links" className="my-5" style={{ paddingTop: '100px' }}>
			{links.map((link, idx) => {
				return (
					<div key={idx}>
						<a href={link.url} rel="noreferrer" target="_blank">
							{link.title}
						</a>
					</div>
				)
			})}
		</div>
	);
}

export default LinkList;
