import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Row, Col } from 'react-bootstrap';

import KuulaProject from '../components/KuulaProject';
// import SiteItem from './components/SiteItem';

// import './styles/App.css';
// import './styles/TabStyles.css';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

export default function App() {

	const [ show, setShow ] = useState(true);
	const [ firstLoad, setFirstLoad ] = useState(true);

	function handleClose() {
		setShow(false);
		if ( firstLoad ) {
			setFirstLoad(false);
		}
	}
  
	useEffect(() => {

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

	}, []);

	function showInstructions(e) {
		e.preventDefault();
		setShow(true);
	}

	return (
		<div className="App">
			<Modal show={show} onHide={handleClose}>
				<Modal.Body>
					<img src={`images/welcome-menu.png`} alt="Instructions" style={{ width: '100%' }} />
				</Modal.Body>
				<Modal.Footer style={{ textAlign: 'center', whiteSpace: 'nowrap', justifyContent: 'center' }}>
					<Row>
						<Col>
							<button type="button" className="btn" style={{ backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', fontSize: '1.25em', border: 'solid 1px #000000' }} onClick={handleClose}>
								{firstLoad ? 'TOUR STUDIO' : 'Close'}
							</button>	
						</Col>
						<Col>
							<Link to="/backstage" className="btn" style={{ backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', fontSize: '1.25em', border: 'solid 1px #000000' }} onClick={handleClose}>
								LEARN MORE
							</Link>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>

			<KuulaProject />
		</div>
	)
}