import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

// import Slider from './Slider';

import './Backstage.css';

import Header from '../components/Header'
import Footer from '../components/Footer'
import LinkList from '../components/LinkList'

function Links() {

	useEffect(() => {
		// vidRef.current.play();
	}, [])

	return (
		<>
			<Header />
			<Container>
				<LinkList />
			</Container>
			<Footer />
		</>
	);
}

export default Links;
